<!-- Admin下页面头部,包含面包屑和当前页面功能按钮等 -->
<template>
  <el-row class="page-header">
    <el-col :span="12">
      <Breadcrumb></Breadcrumb>
    </el-col>
    <el-col :span="12">
      <div class="page-header-btn">
        <slot name="button"></slot>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  defineComponent,
} from "vue";
import Breadcrumb from "../Breadcrumb";
export default defineComponent({
  name: "HeaderRow",
  components: {
    Breadcrumb,
  },
  setup() {
    const data = reactive({});
    onBeforeMount(() => {});
    onMounted(() => {});
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
});
</script>
<style scoped style lang="scss">
.page-header {
  height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  &-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0 20px;
  }
}
</style>
