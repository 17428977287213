<template>
  <div class="breadcrumb-container">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-for="(item, index) in breadList"
        :key="index"
        :to="{ path: item.path }"
        :class="[index === 0 ? 'home' : '']"
        >{{ item.meta.title }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
import { fileURLToPath } from "url";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      breadList: [], // 路由集合
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  methods: {
    isHome(route) {
      return route.name === "Admin";
    },
    getBreadcrumb() {
      // console.log(this.$route);
      let matched = this.$route.matched;
      // if (!this.isHome(matched[0])) {
      //   matched = [{ path: "/admin", meta: { title: "首页" } }].concat(matched);
      // }
      // 过滤admin
      const filterMatched = matched.filter((v, i) => {
        return v.name !== "Admin";
      });
      this.breadList = filterMatched;
    },
  },
  created() {
    this.getBreadcrumb();
  },
});
</script>

<style scoped lang="scss">
.breadcrumb-container {
  // position: absolute;
  // top: 44px;
  &::v-deep(.home .el-breadcrumb__inner.is-link) {
    font-size: 24px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #227fff;
    line-height: 45px;
  }
  &::v-deep(.el-breadcrumb__item:not(:first-child)) {
    font-size: 24px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #81868e;
    line-height: 45px;
  }
}
</style>
